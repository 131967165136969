/*eslint strict: ["error", "global"]*/

'use strict';

/**
 * Object.assign polyfill
 */
if (typeof Object.assign !== 'function') {
	// Must be writable: true, enumerable: false, configurable: true
	Object.defineProperty(Object, 'assign', {
		value: function assign(target, varArgs) { // .length of function is 2
			if (target === null || target === undefined) {
				throw new TypeError('Cannot convert undefined or null to object');
			}

			var to = Object(target);

			for (var index = 1; index < arguments.length; index++) {
				var nextSource = arguments[index];

				if (nextSource !== null && nextSource !== undefined) {
					for (var nextKey in nextSource) {
						// Avoid bugs when hasOwnProperty is shadowed
						if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
							to[nextKey] = nextSource[nextKey];
						}
					}
				}
			}
			return to;
		},
		writable: true,
		configurable: true
	});
}

/**
 * Link + disclosure navigation widget
 * Builds widget navigation for mobile and desktop views from main navigation element
 *
 * Based on:
 * @see https://adrianroselli.com/2019/06/link-disclosure-widget-navigation.html
 * @see https://w3c.github.io/aria-practices/examples/disclosure/disclosure-navigation-hybrid.html
 * @see https://inclusive-components.design/menus-menu-buttons/
 *
 * @version 2.0.0
 *
 * @class
 * @param {Element} domNode Navigation DOM element
 * @param {Object}  options Additional options
 */
var NavigationWidget = function (domNode, options) {
	/**
	 * @typedef WidgetConfig
	 * @type {Object}
	 * @property {string} css_namespace
	 * Default navigation widget desktop version CSS namespace
	 *
	 * @property {string} cloned_css_class
	 * CSS class added to cloned DOM elements
	 *
	 * @property {boolean} display_submenus
	 * Display submenus in desktop version
	 *
	 * @property {boolean} keep_one_submenu_open
	 * Keep only one submenu open at all times in desktop version
	 *
	 * @property {string} submenu_button_icon
	 * SVG icon string. Inserted into submenu button DOM element.
	 *
	 * @property {array} submenu_button_classes
	 * Additional CSS classes to append to submenu button DOM element
	 *
	 * @property {string} stylesheet
	 * Widget stylesheet URL
	 *
	 * @property {string} label_button_submenu
	 * Submenu button label text. %s is replaced with parent menu item link text.
	 *
	 * @property {string} parent_node_css_class
	 * Parent item link DOM element CSS class
	 *
	 * @property {string} focus_selector
	 * CSS selector for DOM element which should receive focus when sub menu is opened
	 *
	 * @property {string} submenu_selector
	 * CSS selector for submenu DOM element
	 *
	 * @property {boolean} mobile_navigation
	 * Wheter navigation widget type is mobile navigation
	 *
	 * @property {boolean} mobile_navigation_clone_node
	 * Wheter to clone mobile navigation DOM element or not
	 *
	 * @property {string} mobile_navigation_media_query
	 * CSS media query when mobile navigation widget is visible
	 *
	 * @property {boolean} mobile_navigation_display_submenus
	 * Wheter to display submenus and buttons in mobile navigation widget
	 *
	 * @property {boolean} mobile_navigation_display
	 * Display mobile navigation widget
	 *
	 * @property {string} mobile_navigation_css_namespace
	 * Mobile navigation widget CSS namespace
	 *
	 * @property {string} mobile_navigation_button_icon
	 * SVG icon string. Inserted into mobile navigation widget button DOM node.
	 *
	 * @property {array} mobile_navigation_button_icon_classes
	 * Additional CSS classes to append to mobile navigation button DOM node
	 *
	 * @property {boolean} mobile_navigation_menubar
	 * Build menubar for mobile view and include mobile navigation widgets in it
	 *
	 * @property {string} mobile_navigation_menubar_parent_node_selector
	 * Mobile menubar parent DOM node CSS selector
	 *
	 * @property {string} mobile_navigation_menubar_insert_position
	 * Where in relative to parent DOM node menubar HTML content is placed with `Element.insertAdjacentHTML()` method
	 *
	 * @property {array} mobile_navigation_menubar_menus
	 * Additional navigation widgets to include in menubar
	 *
	 * @property {string} mobile_navigation_label_button
	 * Mobile navigation button visually displayed text
	*/

	/**
	 * Widget script DOM node
	 *
	 * @property {Element} javascriptElement
	 */
	this.javascriptElement = document.getElementById('pwdtjs-ldwnav-js');

	/**
	 * Error message prefix
	 *
	 * @type {string}
	 */
	var msgPrefix = 'NavigationWidget ';

	/**
	 * Configuration parameters from widget script DOM node data attribute value
	 *
	 * @type {string}
	 */
	var widgetConfigValue = this.javascriptElement.getAttribute('data-ldwnav-config');

	/**
	 * Parsed configuration parameters as JSON
	 *
	 * @type {Object}
	 */
	var widgetConfig = widgetConfigValue ? JSON.parse(widgetConfigValue) : null;

	if (!(domNode instanceof Element)) {
		throw new TypeError(msgPrefix + 'is not a DOM Element.');
	}

	/** @property {Element} domNode Navigation DOM element */
	this.domNode = domNode;

	/** @property {Element} rootNode Navigation DOM element root DOM element */
	this.rootNode = domNode.parentNode;

	/** @property {array} buttons All submenu button class instances */
	this.buttons = [];

	/** @property {array} subMenusWidgets All submenu class instances */
	this.subMenuWidgets = [];

	/** @property {array} mobileNavigationWidgets All mobile menu class instances */
	this.mobileNavigationWidgets = [];

	/** @property {array} mobileMenuDomNodes All mobile menu DOM elements */
	this.mobileMenuDomNodes = [];

	/** @property {Element|null} mobileMenuBarDomNode Mobile navigation menubar DOM element */
	this.mobileMenuBarDomNode = null;

	/** @property {(null|function)} this.resizeObserverTimeOut Mobile menu timeout function */
	this.resizeObserverTimeOut = null;

	/** @property {function} resizeObserver Reference to NavigationWidget.mobileMenuResizeObserver function */
	this.resizeObserver = this.mobileMenuResizeObserver.bind(this);

	/**
	 * Default sub menu widget button SVG icon
	 *
	 * @type {string}
	 */
	var defaultSubmenuButtonIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="11" viewBox="0 0 17 11" aria-hidden="true" focusable="false">' +
		'<path fill="currentColor" d="M9.1606812,10.0430839 L16.726362,2.47736506 C17.0912127,2.11251439 17.0912127,1.52096739 16.726362,1.1560787 L15.8439457,0.273662416 C15.4796653,-0.0906180028 14.8892968,-0.0913023117 14.52418,0.272103713 L8.50001901,6.268056 L2.47581997,0.272103713 C2.11070317,-0.0913023117 1.52033471,-0.0906180028 1.15605429,0.273662416 L0.273638007,1.1560787 C-0.0912126691,1.52092937 -0.0912126691,2.11247637 0.273638007,2.47736506 L7.83935682,10.0430839 C8.20424551,10.4079345 8.79579251,10.4079345 9.1606812,10.0430839 Z"/>' +
		'</svg>';

	/**
	 * Default mobile menu widget button SVG icon
	 *
	 * @type {string}
	 */
	var defaultMobileButtonIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">' +
		'<path fill="currentColor" class="path-open" fill-rule="nonzero" d="M20,16.1666667 L20,17.8333333 C20,18.2890625 19.6223958,18.6666667 19.1666667,18.6666667 L0.833333333,18.6666667 C0.377604167,18.6666667 0,18.2890625 0,17.8333333 L0,16.1666667 C0,15.7109375 0.377604167,15.3333333 0.833333333,15.3333333 L19.1666667,15.3333333 C19.6223958,15.3333333 20,15.7109375 20,16.1666667 Z M20,9.5 L20,11.1666667 C20,11.6223958 19.6223958,12 19.1666667,12 L0.833333333,12 C0.377604167,12 0,11.6223958 0,11.1666667 L0,9.5 C0,9.04427083 0.377604167,8.66666667 0.833333333,8.66666667 L19.1666667,8.66666667 C19.6223958,8.66666667 20,9.04427083 20,9.5 Z M20,2.83333333 L20,4.5 C20,4.95572917 19.6223958,5.33333333 19.1666667,5.33333333 L0.833333333,5.33333333 C0.377604167,5.33333333 0,4.95572917 0,4.5 L0,2.83333333 C0,2.37760417 0.377604167,2 0.833333333,2 L19.1666667,2 C19.6223958,2 20,2.37760417 20,2.83333333 Z"/>' +
		'<path fill="currentColor" class="path-close" d="M3.95980192,1.27526944 L10,7.316 L16.0401981,1.27526944 C16.407224,0.908243519 17.0154384,0.908243519 17.3824643,1.27526944 L18.7247306,2.61753568 C19.0917565,2.98456161 19.0917565,3.592776 18.7247306,3.95980192 L12.684,10 L18.7247306,16.0401981 C19.0917565,16.407224 19.0917565,17.0154384 18.7247306,17.3824643 L17.3824643,18.7247306 C17.0154384,19.0917565 16.407224,19.0917565 16.0401981,18.7247306 L10,12.684 L3.95980192,18.7247306 C3.592776,19.0917565 2.98456161,19.0917565 2.61753568,18.7247306 L1.27526944,17.3824643 C0.908243519,17.0154384 0.908243519,16.407224 1.27526944,16.0401981 L7.316,10 L1.27526944,3.95980192 C0.908243519,3.592776 0.908243519,2.98456161 1.27526944,2.61753568 L2.61753568,1.27526944 C2.98456161,0.908243519 3.592776,0.908243519 3.95980192,1.27526944 Z"/>' +
		'</svg>';

	/**
	 * Widget configuration
	 *
	 * @type {WidgetConfig}
	 */
	this.config = {
		css_namespace: 'nav-main',
		cloned_css_class: '',
		parent_node_css_class: '',
		display_submenus: true,
		keep_one_submenu_open: true,
		submenu_button_icon: defaultSubmenuButtonIcon,
		submenu_button_classes: [],
		stylesheet: '',
		submenu_selector: 'ul',
		label_button_submenu: '%s alasivut',
		focus_selector: 'a',
		mobile_navigation: false,
		mobile_navigation_clone_node: true,
		mobile_navigation_menubar: false,
		mobile_navigation_label_button: 'Valikko',
		mobile_navigation_menubar_parent_node_selector: 'body',
		mobile_navigation_menubar_insert_position: 'afterbegin',
		mobile_navigation_media_query: '(max-width: 991px)',
		mobile_navigation_display_submenus: true,
		mobile_navigation_display: true,
		mobile_navigation_css_namespace: 'nav-mobile',
		mobile_navigation_button_icon: defaultMobileButtonIcon,
		mobile_navigation_button_icon_classes: [],
		mobile_navigation_menubar_menus: [],
		mobile_navigation_parent_node_css_class: 'nav-mobile-parent',
	};

	if (widgetConfig) {
		this.config = Object.assign(this.config, widgetConfig);
	}

	if (options) {
		this.config = Object.assign(this.config, options);
	}

	this.config.cloned_css_class = this.config.cloned_css_class || this.config.mobile_navigation_css_namespace + '__item--cloned';
	this.config.parent_node_css_class = this.config.parent_node_css_class || this.config.css_namespace + '__link--parent';

	/**
	 * Clone mobile navigation DOM node always if both sub menus
	 * in original DOM node and mobile navigation are displayed.
	 */
	if (!this.config.mobile_navigation_clone_node && this.config.display_submenus && this.config.mobile_navigation_display) {
		this.config.mobile_navigation_clone_node = true;
	}
};

/**
 * Initialize navigation widget
 */
NavigationWidget.prototype.init = function () {
	var config = this.config;

	if (!config.mobile_navigation_display && !config.display_submenus) {
		return;
	}

	if (config.mobile_navigation_display && !config.mobile_navigation) {
		if (config.mobile_navigation_menubar &&
			config.mobile_navigation_menubar_menus &&
			config.mobile_navigation_menubar_menus.length) {

			for (var i = 0; i < config.mobile_navigation_menubar_menus.length; i++) {
				var menuOptions = config.mobile_navigation_menubar_menus[i];

				if (menuOptions.type === 'element') {
					var menubarMenuDomNode = document.querySelector(menuOptions.selector);

					if (menubarMenuDomNode) {
						var clonedMenubarMenuDomNode = config.mobile_navigation_clone_node ? menubarMenuDomNode.cloneNode(true) : menubarMenuDomNode;

						clonedMenubarMenuDomNode.navigation_widget_options = menuOptions;
						this.mobileMenuDomNodes.push(clonedMenubarMenuDomNode);
						menubarMenuDomNode.classList.add(config.cloned_css_class);
					}
				}
			}
		}

		var mobileMenuDomNode = config.mobile_navigation_clone_node ? this.domNode.cloneNode(true) : this.domNode;

		this.mobileMenuDomNodes.push(mobileMenuDomNode);

		if (config.mobile_navigation_clone_node) {
			this.domNode.classList.add(this.config.cloned_css_class);
		}
	}

	this.initSubMenus(this.domNode);

	if (!config.mobile_navigation) {
		this.initMobileMenus();
	}

	document.addEventListener('click', this.onBlur.bind(this));
	document.addEventListener('keyup', this.onBlur.bind(this));
};

/**
 * Load widget stylesheet
 */
NavigationWidget.prototype.loadCss = function () {
	var config = this.config;
	var styleSheetId = 'pwdtjs-ldwnav-css';

	if ('stylesheet' in config && config.stylesheet !== '' && !document.getElementById(styleSheetId)) {
		var stylesheetNode = document.createElement('link');
		stylesheetNode.rel = 'stylesheet';
		stylesheetNode.href = config.stylesheet;
		stylesheetNode.id = styleSheetId;
		stylesheetNode.media = 'all';
		this.javascriptElement.parentNode.insertBefore(stylesheetNode, this.javascriptElement);
	}
};

/**
 * Initialize navigation widget sub menus
 *
 * @param {Element} domNode Navigation widget DOM node
 */
NavigationWidget.prototype.initSubMenus = function (domNode) {
	var config = this.config;
	var subMenuNodes = domNode.querySelectorAll(config.submenu_selector);
	var displaySubMenus = config.display_submenus && !config.mobile_navigation ||
		(config.mobile_navigation && config.mobile_navigation_display_submenus);

	if (displaySubMenus) {
		this.loadCss();
	}

	for (var i = 0; i < subMenuNodes.length; i++) {
		var subMenuNode = subMenuNodes[i];

		subMenuNode.setAttribute('hidden', '');

		if (displaySubMenus) {
			var subMenuWidget = new NavigationSubMenuWidget(subMenuNode, null, this);

			subMenuWidget.init();
			subMenuWidget.openSubMenus();
			this.subMenuWidgets.push(subMenuWidget);
		}
	}
};

/**
 * Initialize mobile menus
 *
 * @param {boolean} force Force menus to initialize
 */
NavigationWidget.prototype.initMobileMenus = function (force) {
	var config = this.config;
	var mediaQuery = 'matchMedia' in window ? config.mobile_navigation_media_query : '';

	if (!this.mobileMenuDomNodes.length) {
		return;
	}

	force = Boolean(force);

	if (mediaQuery === '' || force || window.matchMedia(mediaQuery).matches) {
		this.loadCss();

		if (config.mobile_navigation_menubar) {
			this.buildMobileMenuBar();
		}

		for (var i = 0; i < this.mobileMenuDomNodes.length; i++) {
			var menuDomNode = this.mobileMenuDomNodes[i];
			var menuDomNodeOptions = menuDomNode.navigation_widget_options ? menuDomNode.navigation_widget_options : null;
			var mobileMenuWidget = new MobileNavigationWidget(this.mobileMenuDomNodes[i], this, menuDomNodeOptions, window.pwdNavigationWidget);

			mobileMenuWidget.init();

			this.mobileNavigationWidgets.push(mobileMenuWidget);
		}

		return;
	}

	if (!window.matchMedia(mediaQuery).matches) {
		window.addEventListener('resize', this.resizeObserver);
	}
};

/**
 * Observe if media query matches after window "resize" event
 *
 * @this {NavigationWidget}
 */
NavigationWidget.prototype.mobileMenuResizeObserver = function () {
	if (!this.resizeObserverTimeOut) {
		this.resizeObserverTimeOut = setTimeout(function () {
			this.resizeObserverTimeOut = null;

			if (window.matchMedia(this.config.mobile_navigation_media_query).matches) {
				window.removeEventListener('resize', this.resizeObserver);
				this.initMobileMenus(true);
			}
		}.bind(this), 1000);
	}
};

/**
 * Build mobile menu bar DOM node
 */
NavigationWidget.prototype.buildMobileMenuBar = function () {
	var config = this.config;
	var menuBarParentNode = config.mobile_navigation_menubar_parent_node_selector !== '' ? document.querySelector(config.mobile_navigation_menubar_parent_node_selector) : null;

	if (!menuBarParentNode || !config.mobile_navigation_menubar_insert_position) {
		return;
	}

	var menuBarDomNode = this.domNode.parentNode.cloneNode();
	var menuBarDomNodeDefaultClassName = config.mobile_navigation_css_namespace + '--menubar';
	var menuBarDomNodeClassNames = [
		config.mobile_navigation_css_namespace,
		menuBarDomNodeDefaultClassName
	];
	var menuBarWrapDomNodeClassName = config.mobile_navigation_css_namespace + '__menubar-wrap';

	if (config.mobile_navigation_parent_node_css_class !== '') {
		menuBarDomNodeClassNames.push(config.mobile_navigation_parent_node_css_class);
	}

	var menuBarDomNodeClassName = menuBarDomNodeClassNames.join(' ');

	menuBarDomNode.className = menuBarDomNodeClassName;
	menuBarDomNode.insertAdjacentHTML('afterbegin', '<div class="' + menuBarWrapDomNodeClassName + '"></div>');
	menuBarParentNode.insertAdjacentHTML(config.mobile_navigation_menubar_insert_position, menuBarDomNode.outerHTML);
	this.mobileMenuBarDomNode = document.querySelector('.' + menuBarDomNodeDefaultClassName);
};

/**
 * Helper function to remove all SVG DOM elements inside a specific DOM node
 *
 * @param {Element} domNode DOM node from where to look for SVG DOM nodes
 */
NavigationWidget.prototype.removeSvgFromNode = function (domNode) {
	var svgNodes = Array.prototype.slice.call(domNode.querySelectorAll('svg'));

	if (!svgNodes.length) {
		return;
	}

	for (var i = 0; i < svgNodes.length; i++) {
		svgNodes[i].parentNode.removeChild(svgNodes[i]);
	}
};

/**
 * Helper function to handle additional links parent DOM nodes
 *
 * @param {Object}  linkItem Link element properties
 * @param {Element} linkNode Link DOM node
 */
NavigationWidget.prototype.handleLinkParents = function (linkItem, linkNode) {
	var clonedCssClass = this.config.cloned_css_class;

	if (linkItem.hide_parent && linkItem.hide_parent === true) {
		var linkParentNode = linkNode.parentNode;

		if (!linkParentNode.classList.contains(clonedCssClass)) {
			linkParentNode.classList.add(clonedCssClass);
		}
	}

	if (linkItem.hide_ancestor && linkItem.hide_ancestor === true) {
		var linkAncestorNode = linkNode.parentNode.parentNode;

		if (!linkAncestorNode.classList.contains(clonedCssClass)) {
			linkAncestorNode.classList.add(clonedCssClass);
		}
	}
};

/**
 * Check if submenu is in root level
 *
 * @param {NavigationSubMenuWidget} subMenuWidget Submenu widget
 *
 * @return {Boolean}
 */
NavigationWidget.prototype.isRootLevelSubMenu = function (subMenuWidget) {
	subMenuWidget = subMenuWidget || this;

	var config = this.config;
	var parentNode = subMenuWidget.domNode.parentNode.parentNode;
	var className = config.css_namespace + (config.mobile_navigation ? '__list' : '__sub-list');

	return !parentNode.classList.contains(className);
};

/**
 * Closes all open submenus
 *
 * @param {Object}                  event               Event object (optional)
 * @param {NavigationSubMenuWidget} activeSubMenuWidget Active submenu widget instance (optional)
 */
NavigationWidget.prototype.closeAllMenus = function (event, activeSubMenuWidget) {
	for (var i = 0; i < this.subMenuWidgets.length; i++) {
		var subMenuWidget = this.subMenuWidgets[i];

		if (subMenuWidget.isOpen &&
			this.isRootLevelSubMenu(subMenuWidget) &&
			subMenuWidget !== activeSubMenuWidget) {
			subMenuWidget.toggleMenu(event);
		}
	}
};

/**
 * Handle focusing out from root node event
 *
 * @param {Object} event Event object
 */
NavigationWidget.prototype.onBlur = function (event) {
	if (!this.rootNode.contains(event.target)) {
		this.closeAllMenus(false);
	}
};

/**
 * Submenu widget
 *
 * @class
 * @param {Element}          domNode          Submenu widget DOM node
 * @param {Element}          buttonNode		 Submenu widget visibility trigger button DOM node
 * @param {NavigationWidget} navigationWidget NavigationWidget class instance
 */
var NavigationSubMenuWidget = function (domNode, buttonNode, navigationWidget) {
	/**
	 * Sub menu widget DOM element
	 *
	 * @property {Element}
	 */
	this.domNode = domNode;

	/**
	 * NavigationWidget class instance
	 *
	 * @property {NavigationWidget}
	 */
	this.navigationWidget = navigationWidget;

	/**
	 * Submenu widget visibility toggle button DOM element
	 *
	 * @property {Element}
	 */
	this.buttonNode = buttonNode;

	/**
	 * NavigationWidgetButton class instance
	 *
	 * @property {NavigationWidgetButton}
	 */
	this.buttonWidget = null;

	/**
	 * DOM element which contains button DOM element text content
	 *
	 * @property {Element}
	 */
	this.buttonTextNode = null;

	/**
	 * Wheter submenu is open
	 *
	 * @property {boolean}
	 */
	this.isOpen = false;
};

/**
 * Sub menu widget initialization
 */
NavigationSubMenuWidget.prototype.init = function () {
	var config = this.navigationWidget.config;

	if (!this.buttonNode) {
		var buttonNode = document.createElement('button');
		var buttonClassName = config.css_namespace + '__sub-button';
		var buttonTextElement = document.createElement('span');
		var buttonIcon = config.submenu_button_icon && config.submenu_button_icon !== '' ? document.createRange().createContextualFragment(config.submenu_button_icon).querySelector('svg') : '';
		var buttonSiblingNode = this.domNode.parentNode.querySelector('a');

		buttonNode.className = buttonClassName;
		buttonNode.setAttribute('aria-expanded', 'false');
		buttonTextElement.className = 'screen-reader-text ' + config.css_namespace + '__button-text';
		buttonTextElement.textContent = config.label_button_submenu.replace('%s', buttonSiblingNode.textContent);
		buttonNode.setAttribute('type', 'button');

		if ('submenu_button_classes' in config &&
			Array.isArray(config.submenu_button_classes) &&
			config.submenu_button_classes.length) {
			buttonNode.className += ' ' + config.submenu_button_classes.join(' ');
		}

		if (buttonIcon) {
			buttonIcon.className.baseVal = buttonClassName + '-icon';
			buttonNode.appendChild(buttonIcon);
		}

		buttonNode.appendChild(buttonTextElement);
		this.buttonNode = buttonNode;
		this.buttonTextNode = buttonTextElement;
		buttonSiblingNode.parentNode.insertBefore(buttonNode, buttonSiblingNode.nextSibling);
		this.navigationWidget.buttons.push(buttonNode);
	}

	var navigationWidgetButton = new NavigationWidgetButton(buttonNode, this, this.navigationWidget);
	navigationWidgetButton.init();

	this.buttonWidget = navigationWidgetButton;
	this.domNode.addEventListener('keydown', this.handleKeyDown.bind(this));
};

/**
 * Toggle sub menu visibility
 *
 * @param {boolean} focusOnClose Define wheter button should receive focus when submenu is closed (default: true)
 */
NavigationSubMenuWidget.prototype.toggleMenu = function (focusOnClose) {
	var config = this.navigationWidget.config;
	var focusDomNode = config.focus_selector ? this.domNode.querySelector(config.focus_selector) : null;
	var expanded = this.buttonNode.getAttribute('aria-expanded') === 'true' || false;
	var buttonSiblingNode = this.buttonNode.parentNode.querySelector('a');
	var siblingNodeOpenClassName = config.css_namespace + '__link--open';

	focusOnClose = typeof focusOnClose === 'undefined' ? true : Boolean(focusOnClose);

	this.buttonNode.setAttribute('aria-expanded', !expanded);

	if (!expanded) {
		this.isOpen = true;
		buttonSiblingNode.classList.add(siblingNodeOpenClassName);
		this.domNode.removeAttribute('hidden');

		// Make sure sub menu doesn't overflow from viewport

		if (!config.mobile_navigation) {
			var windowWidth = window.outerWidth;
			var domNodeWidth = this.domNode.offsetWidth;
			var clientRect = this.domNode.getBoundingClientRect();
			var domNodeRightOffset = windowWidth - (clientRect.x + domNodeWidth);

			if (clientRect.x < 0) {
				this.domNode.style.left = 0;
			}

			if (domNodeRightOffset < 0) {
				this.domNode.style.left = Math.ceil(domNodeRightOffset) + 'px';
			}
		}

		if (focusDomNode) {
			focusDomNode.focus();
		}
	} else {
		this.domNode.setAttribute('hidden', '');
		this.domNode.style.cssText = '';
		buttonSiblingNode.classList.remove(siblingNodeOpenClassName);
		this.isOpen = false;

		if (focusOnClose) {
			this.buttonNode.focus();
		}
	}
};

/**
 * Handle submenu keyboard events
 *
 * @param {Object} event Event object
 */
NavigationSubMenuWidget.prototype.handleKeyDown = function (event) {
	if (!this.isOpen) {
		return;
	}

	if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27 && this.isOpen) {
		event.stopPropagation();
		this.toggleMenu();
	}
};

/**
 * Open current page tree
 */
NavigationSubMenuWidget.prototype.openSubMenus = function () {
	var config = this.navigationWidget.config;
	var openLinksSelector = '[aria-current]' + (config.parent_node_css_class ? ', .' + config.parent_node_css_class : '');
	var openLinks = this.domNode.querySelector(openLinksSelector);

	if (openLinks && (config.mobile_navigation || !this.navigationWidget.isRootLevelSubMenu(this))) {
		this.toggleMenu();
	}
};

/**
 * Navigation button widget for toggling menu visibility
 *
 * @class
 * @param {Element}                 domNode          Button DOM element
 * @param {NavigationSubMenuWidget} subMenuWidget    Button target sub menu widget class instance
 * @param {NavigationWidget}        navigationWidget NavigationWidget class instance
 */
var NavigationWidgetButton = function (domNode, subMenuWidget, navigationWidget) {
	/**
	 * Submenu widget DOM element
	 *
	 * @property {Element}
	 */
	this.domNode = domNode;

	/**
	 * Button target submenu widget class instance
	 *
	 * @property {NavigationSubMenuWidget}
	 */
	this.subMenuWidget = subMenuWidget;

	/**
	 * Navigation widget class instance
	 *
	 * @property {NavigationWidget}
	 */
	this.navigationWidget = navigationWidget;

	/**
	 * DOM element which holds button DOM element text content
	 *
	 * @property {Element}
	 */
	this.textDomNode = null;
};

/**
 * Navigation button widget initialization
 */
NavigationWidgetButton.prototype.init = function () {
	var linkNode = this.domNode.parentNode.querySelector('a');
	var dataItemId = linkNode.getAttribute('data-item-id');
	var config = this.navigationWidget.config;
	var idPrefix = config.mobile_navigation && config.mobile_navigation_css_namespace ? config.mobile_navigation_css_namespace + '-' : '';
	var subMenuWidget = this.subMenuWidget;
	var isRootLevelSubMenu = this.navigationWidget.isRootLevelSubMenu(this.subMenuWidget);

	if (dataItemId) {
		var linkNodeId = idPrefix + config.css_namespace + '-link-' + dataItemId;
		var buttonNodeId = idPrefix + config.css_namespace + '-button-' + dataItemId;
		var menuNodeId = idPrefix + config.css_namespace + '-menu-' + dataItemId;

		linkNode.id = linkNodeId;
		this.domNode.id = buttonNodeId;
		this.subMenuWidget.domNode.id = menuNodeId;
		this.domNode.setAttribute('aria-controls', menuNodeId);
	}

	this.textDomNode = this.domNode.querySelector('.' + config.css_namespace + '__button-text');

	if (!config.mobile_navigation &&
		config.keep_one_submenu_open &&
		isRootLevelSubMenu) {
		this.domNode.addEventListener('click', this.navigationWidget.closeAllMenus.bind(this.navigationWidget, event, subMenuWidget));
	}

	this.domNode.addEventListener('click', this.subMenuWidget.toggleMenu.bind(this.subMenuWidget));

	if (isRootLevelSubMenu) {
		this.domNode.addEventListener('keydown', this.handleKeyDown.bind(this));
	}
};

/**
 * Handle submenu button keyboard events
 *
 * @param {Object} event Event object
 */
NavigationWidgetButton.prototype.handleKeyDown = function (event) {
	if (!this.subMenuWidget.isOpen) {
		return;
	}

	if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
		event.stopPropagation();
		this.subMenuWidget.toggleMenu();
	}
};

/**
 * Mobile navigation widget
 *
 * @class
 * @param {Element}          domNode              Mobile navigation widget DOM element
 * @param {NavigationWidget} navigationWidget     Navigation widget class instance
 * @param {Object}           options              Widget specific options
 * @param {NavigationWidget} mainNavigationWidget Main navigation widget class instance
 */
var MobileNavigationWidget = function (domNode, navigationWidget, options, mainNavigationWidget) {
	/**
	 * Mobile navigation widget DOM element
	 * @property {Element}
	 */
	this.domNode = domNode;

	/**
	 * Mobile navigation button DOM element
	 * @property {Element}
	 */
	this.buttonNode = null;

	/**
	 * Navigation widget class instance
	 * @property {NavigationWidget}
	 */
	this.navigationWidget = navigationWidget;

	/**
	 * Widget specific options
	 * @property {Object}
	 */
	this.options = options;

	/**
	 * Main navigation widget class instance
	 * @property {NavigationWidget}
	 */
	this.mainNavigationWidget = mainNavigationWidget;
};

/**
 * Mobile navigation widget initialization
 */
MobileNavigationWidget.prototype.init = function () {
	var options = this.options;
	var config = Object.assign({}, this.navigationWidget.config, options);
	var widgetType = options && options.type ? options.type : 'navigation';
	var cssNameSpace = config.mobile_navigation_css_namespace;
	var widgetId = cssNameSpace + '__list';
	var buttonNode = document.createElement('button');
	var buttonIcon = config.mobile_navigation_button_icon && config.mobile_navigation_button_icon !== '' ? document.createRange().createContextualFragment(config.mobile_navigation_button_icon).querySelector('svg') : '';
	var buttonParentNode = this.navigationWidget.mobileMenuBarDomNode && this.navigationWidget.mobileMenuBarDomNode.querySelector('*') ? this.navigationWidget.mobileMenuBarDomNode.querySelector('*') : this.navigationWidget.domNode.parentNode;

	document.documentElement.classList.add(cssNameSpace + '-loaded');
	this.domNode.classList.add(cssNameSpace + '__menu');

	if (options && options.id) {
		widgetId = cssNameSpace + '-' + options.id;
		this.domNode.classList.add(cssNameSpace + '__' + options.id + '-menu');
	}

	if (!this.navigationWidget.mobileMenuBarDomNode && config.mobile_navigation_parent_node_css_class !== '') {
		this.navigationWidget.domNode.parentNode.classList.add(config.mobile_navigation_parent_node_css_class);
	}

	if (widgetType === 'navigation') {
		this.domNode.classList.add(cssNameSpace + '__list');
		this.handleAdditionalLinks(this.domNode, config);
	} else {
		this.handleDuplicateIds(options);
	}

	this.domNode.id = widgetId;
	this.domNode.setAttribute('hidden', '');
	this.domNode.isOpen = false;

	buttonNode.className = cssNameSpace + '__button';
	buttonNode.setAttribute('aria-expanded', 'false');
	buttonNode.setAttribute('aria-controls', this.domNode.id);
	buttonNode.setAttribute('type', 'button');

	if (buttonIcon) {
		var buttonIconPathOpen = buttonIcon.querySelector('.path-open');
		var buttonIconPathClose = buttonIcon.querySelector('.path-close');

		buttonIcon.className.baseVal = buttonNode.className + '-icon';

		if (config.mobile_navigation_button_icon_classes.length) {
			buttonIcon.className.baseVal += ' ' + config.mobile_navigation_button_icon_classes.join(' ');
		}

		if (cssNameSpace) {
			if (buttonIconPathOpen) {
				buttonIconPathOpen.className.baseVal = cssNameSpace + '__icon-' + buttonIconPathOpen.className.baseVal;
			}

			if (buttonIconPathClose) {
				buttonIconPathClose.className.baseVal = cssNameSpace + '__icon-' + buttonIconPathClose.className.baseVal;
			}
		}

		buttonNode.appendChild(buttonIcon);
	}

	buttonNode.insertAdjacentHTML('beforeend', '<span class="' + config.css_namespace + '__button-text screen-reader-text">' + config.mobile_navigation_label_button + '</span>');

	this.buttonNode = buttonNode;

	if (this.navigationWidget.mobileMenuBarDomNode) {
		var menuBarItemDomNode = document.createElement('div');

		menuBarItemDomNode.className = cssNameSpace + '__menubar-item';
		menuBarItemDomNode.appendChild(buttonNode);
		menuBarItemDomNode.appendChild(this.domNode);
		buttonParentNode.appendChild(menuBarItemDomNode);
	} else {
		buttonParentNode.appendChild(buttonNode);
		buttonParentNode.appendChild(this.domNode);
	}

	var mobileNavigationOptions = Object.assign(
		{},
		config,
		options,
		{
			keep_one_submenu_open: false,
			mobile_navigation: true
		}
	);
	var mobileNavigationWidget = new NavigationWidget(this.domNode, mobileNavigationOptions);

	mobileNavigationWidget.init();

	var subMenuWidget = new NavigationSubMenuWidget(this.domNode, buttonNode, mobileNavigationWidget);

	subMenuWidget.buttonTextNode = buttonNode.querySelector('.' + config.css_namespace + '__button-text');
	this.navigationWidget.subMenuWidgets.push(subMenuWidget);
	this.domNode.addEventListener('keydown', subMenuWidget.handleKeyDown.bind(subMenuWidget));

	var menuButtonWidget = new MobileNavigationWidgetButton(buttonNode, subMenuWidget, mobileNavigationWidget, this.mainNavigationWidget);

	menuButtonWidget.init();
};

/**
 * Get reference node for additional link node
 *
 * @param {Element} node Parent node
 *
 * @return {Element|null} Reference node if found, `null` otherwise
 */
MobileNavigationWidget.prototype.getReferenceNode = function (node) {
	if (!node) {
		return null;
	}

	for (var i = 0; i < node.childNodes.length; i++) {
		var childNode = node.childNodes[i];

		for (var x = 0; x < childNode.childNodes.length; x++) {
			var subChildNode = childNode.childNodes[x];

			if ('tagName' in subChildNode && subChildNode.tagName.toLowerCase() === 'a') {
				return subChildNode;
			}
		}
	}
};

/**
 * Add additional link nodes to mobile navigation
 */
MobileNavigationWidget.prototype.handleAdditionalLinks = function () {
	var referenceLinkNode = this.getReferenceNode(this.domNode);

	if (!referenceLinkNode) {
		return;
	}

	var childNode = this.domNode.firstElementChild.cloneNode();
	var prependLinks = this.getAdditionalLinks('prepend_links', referenceLinkNode);
	var prependContent = '';
	var appendLinks = this.getAdditionalLinks('append_links', referenceLinkNode);
	var appendContent = '';

	childNode.removeAttribute('aria-current');

	if (appendLinks.length) {
		for (var i = 0; i < appendLinks.length; i++) {
			var parentNode = childNode.cloneNode();

			parentNode.innerHTML = appendLinks[i].outerHTML;
			appendContent += parentNode.outerHTML;
		}
	}

	if (prependLinks.length) {
		for (var i = 0; i < prependLinks.length; i++) {
			var parentNode = childNode.cloneNode();

			parentNode.innerHTML = prependLinks[i].outerHTML;
			prependContent += parentNode.outerHTML;
		}
	}

	this.domNode.insertAdjacentHTML('afterbegin', prependContent);
	this.domNode.insertAdjacentHTML('beforeend', appendContent);
};

/**
 * Get additional link nodes for sub menu. Additional links
 * are passed in configuration.
 *
 * @param {string}  key               Additional links configuration key
 * @param {Element} referenceLinkNode Node from which to copy properties for additional link elements
 *
 * @returns {array} Link nodes
 */
MobileNavigationWidget.prototype.getAdditionalLinks = function (key, referenceLinkNode) {
	var config = this.navigationWidget.config;
	var links = [];

	if (!config[key] || !config[key].length) {
		return links;
	}

	var referenceLinkNodeClasses = referenceLinkNode ? referenceLinkNode.className.split(' ') : [];
	var referenceLinkNodeClassName = referenceLinkNodeClasses.length ? referenceLinkNodeClasses[0] : '';

	for (var x = 0; x < config[key].length; x++) {
		var linkItem = config[key][x];

		if (linkItem.selector) {
			var linkNodes = Array.prototype.slice.call(document.querySelectorAll(linkItem.selector));

			if (linkNodes.length) {
				for (var y = 0; y < linkNodes.length; y++) {
					var linkNode = linkNodes[y];

					if (referenceLinkNodeClassName !== '') {
						linkNode.classList.add(referenceLinkNodeClassName);
					}

					var linkNodeCloned = linkNode.cloneNode(true);

					if (linkItem.remove_svg && linkItem.remove_svg === true) {
						this.navigationWidget.removeSvgFromNode(linkNodeCloned);
					}

					this.navigationWidget.handleLinkParents(linkItem, linkNode);
					links.push(linkNodeCloned);
				}
			}
		}

		if (linkItem.url) {
			var linkNode = document.createElement('a');
			linkNode.href = linkItem.url;
			linkNode.innerHTML = '<span>' + linkItem.title + '</span>';

			if (referenceLinkNode) {
				linkNode.className = referenceLinkNode.className;
			}

			if ('current' in linkItem && linkItem.current === true) {
				linkNode.setAttribute('aria-current', 'page');
			}

			if (config.parent_node_css_class) {
				linkNode.classList.remove(config.parent_node_css_class);
			}

			this.navigationWidget.handleLinkParents(linkItem, linkNode);
			links.push(linkNode);
		}
	}

	return links;
};

/**
 * Helper function to handle duplicate "id" attribute values
 *
 * @param {Object} options Options
 */
MobileNavigationWidget.prototype.handleDuplicateIds = function (options) {
	var domNodesWithId = this.domNode.querySelectorAll('[id]');

	for (var i = 0; i < domNodesWithId.length; i++) {
		var domNodeWithId = domNodesWithId[i];
		var domNodesWithForAttribute = this.domNode.querySelectorAll('[for="' + domNodeWithId.getAttribute('id') + '"]');
		var newIdValue = this.navigationWidget.config.mobile_navigation_css_namespace + '-' + (options !== undefined && options.id ? options.id + '-' : '') + domNodeWithId.getAttribute('id');

		domNodeWithId.setAttribute('id', newIdValue);

		for (var x = 0; x < domNodesWithForAttribute.length; x++) {
			domNodesWithForAttribute[x].setAttribute('for', newIdValue);
		}
	}
};

/**
 * Mobile navigation widget button widget
 *
 * @class
 *
 * @param {Element}                 domNode              Mobile navigation widget button node
 * @param {NavigationSubMenuWidget} subMenuWidget        Button target submenu widget class instance
 * @param {NavigationWidget}        navigationWidget     Navigation widget class instance
 * @param {NavigationWidget}        mainNavigationWidget Main navigation widget class instance
 */
var MobileNavigationWidgetButton = function (domNode, subMenuWidget, navigationWidget, mainNavigationWidget) {
	/**
	 * Mobile navigation button HTML element node
	 *
	 * @property {Element}
	 */
	this.domNode = domNode;

	/**
	 * Button target sub menu widget class instance
	 *
	 * @property {NavigationSubMenuWidget}
	 */
	this.subMenuWidget = subMenuWidget;

	/**
	 * Navigation widget class instance
	 * @property {NavigationWidget}
	 */
	this.navigationWidget = navigationWidget;

	/**
	 * Main navigation widget class instance
	 * @property {NavigationWidget}
	 */
	this.mainNavigationWidget = mainNavigationWidget;
};

/**
 * Initialize mobile navigation button widget
 */
MobileNavigationWidgetButton.prototype.init = function () {
	this.domNode.addEventListener('click', this.closeAllMobileSubMenus.bind(this));
	this.domNode.addEventListener('click', this.subMenuWidget.toggleMenu.bind(this.subMenuWidget));
	this.domNode.addEventListener('keydown', this.subMenuWidget.handleKeyDown.bind(this.subMenuWidget));
};

/**
 * Close all mobile sub menus and make sure only 1 can be
 * open simultaneously
 */
MobileNavigationWidgetButton.prototype.closeAllMobileSubMenus = function () {
	if (this.mainNavigationWidget === undefined) {
		return;
	}

	for (var i = 0; i < this.mainNavigationWidget.subMenuWidgets.length; i++) {
		var subMenuWidget = this.mainNavigationWidget.subMenuWidgets[i];

		if (subMenuWidget.isOpen && subMenuWidget !== this.subMenuWidget) {
			subMenuWidget.toggleMenu();
		}
	}
};
