/**
 * Link + disclosure navigation widget initialization
 */

var primaryNavigation = document.querySelector('.nav-main__list:not(.nav-main--secondary)');
// var secondaryNavigation = document.querySelector('.nav-main--secondary.nav-main__list');

if (primaryNavigation) {
	var pwd_ldwnav_widget = new NavigationWidget(primaryNavigation);
	pwd_ldwnav_widget.init();
}

// if (secondaryNavigation) {
// 	var pwd_ldwnav_widget_2 = new NavigationWidget(secondaryNavigation);
// 	pwd_ldwnav_widget_2.init();
// }
